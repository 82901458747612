import Alert from '@vertikal/e-prospera.ui.alert';
import Box from '@vertikal/e-prospera.ui.box';
import Link from '@vertikal/e-prospera.ui.link';
import { ListItem } from '@vertikal/e-prospera.ui.list';
import Loader from '@vertikal/e-prospera.ui.loader';
import Paper from '@vertikal/e-prospera.ui.paper';
import Text from '@vertikal/e-prospera.ui.text';
import { format } from 'date-fns';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { API } from '../../api/api';
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import GlobalSettings from '../../components/GlobalSettings';
import MainMenu from '../../components/MainMenu/MainMenu';
import { ContentWrapper, SubText } from '../../styles/business/search.styles';
import {
  Aside,
  Content,
  Info,
  InlineTag,
  Separator,
  VerifiedIcon,
  EntityDetailsWrapper,
  ProfileDivider,
  DetailList,
} from '../../styles/entities.styles';
import { getPreviousNames } from '../../utils/entities';
import { useGetToken } from './../../utils/authentication';

const PublicEntityDetailPage = (props) => {
  const {
    params: { id },
  } = props;

  const { t } = useTranslation();
  const token = useGetToken();
  const [entity, setEntity] = useState();
  const [isAssociated, setIsAssociated] = useState(false);

  const publicProfile = entity?.publicProfileToggles;

  useEffect(() => {
    async function fetchData() {
      try {
        const req = await fetch(`${API}/business-backend/public/entities/${id}`);
        const res = await req.json();
        if (!req.ok) {
          throw new Error(req.status);
        }
        setEntity(res);
      } catch (error) {
        window.location.href = '/404';
      }
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    if (token) {
      async function getAssociation() {
        try {
          const req = await fetch(`${API}/business-backend/entities/${id}`, {
            headers: { authorization: token },
          });
          if (!req.ok) {
            throw new Error(req.status);
          }
          setIsAssociated(true);
        } catch (error) {
          console.log('error', error);
          setIsAssociated(false);
        }
      }
      getAssociation();
    }
  }, [token, id]);

  const displayAddress =
    (publicProfile?.email && entity?.email) || (publicProfile?.principalOffice && entity?.principalOffice);

  const displayRegisteredAgent =
    (publicProfile?.registeredAgentName && entity?.registeredAgent?.name) ||
    (publicProfile?.registeredAgentRepresentativeName && entity?.registeredAgent?.representativeName) ||
    (publicProfile?.registeredAgentMailingAddress && entity?.registeredAgent?.mailingAddress) ||
    (publicProfile?.registeredAgentAddress && entity?.registeredAgent?.address);

  const displayIndustry =
    (publicProfile?.workInZede && entity?.workInZede !== null) ||
    (publicProfile?.industry && entity?.industry) ||
    (publicProfile?.regulatoryElection && entity?.regulatoryElection) ||
    (publicProfile?.regulatoryElectionSub && entity?.regulatoryElectionSub) ||
    (publicProfile?.natureOfBusiness && entity?.natureOfBusiness);

  const displayInsurance =
    (publicProfile?.insurance && entity?.insurance?.insurance !== null) ||
    (publicProfile?.riskScore && entity?.insurance?.scoreScale !== null);

  const organizers = entity?.organizers?.filter((o) => ['MAIN_ORGANIZER', 'INCORPORATOR'].includes(o.type));

  const displayLegalReps = publicProfile?.mainOrganizerName && !!organizers.length;

  const getAddress = (address) => {
    const { streetAddress, city, postalCode, country } = address;
    return `${streetAddress}, ${city}, ${postalCode}, ${country}`;
  };

  const isForeignEntity = entity?.type === 'FOREIGN_ENTITY';

  return (
    <>
      {entity ? (
        <>
          <Helmet>
            <title>eProspera - {entity.fullName}</title>
          </Helmet>
          <GlobalSettings />
          <MainMenu darkFooter={false}>
            <ContentWrapper>
              <Box style={{ alignSelf: 'center' }}>
                <Breadcrumbs theme="dark">
                  <GatsbyLink to="/">
                    <Trans>common.home</Trans>
                  </GatsbyLink>
                  <GatsbyLink to="/business">
                    <Trans>common.business</Trans>
                  </GatsbyLink>
                  <GatsbyLink to="/business/search">
                    <Trans>common.business_search</Trans>
                  </GatsbyLink>
                  <span>{entity.fullName}</span>
                </Breadcrumbs>
              </Box>
              <EntityDetailsWrapper>
                <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
                  <Info style={{ maxWidth: '60rem' }}>
                    <Box marginBottom="44px" display="flex" flexDirection="column" alignItems="flex-start" gap="0.375">
                      <Text variant="h1">{entity.fullName}</Text>
                      {!!entity?.previousNames?.length && (
                        <SubText>{`${t('common.past_names')}: ${getPreviousNames(entity.previousNames)}`}</SubText>
                      )}
                    </Box>
                    {isAssociated && (
                      <Box marginBottom="48px" maxWidth="400px">
                        <Alert color="info">
                          <Trans
                            components={{
                              a: <Link href={`${process.env.GATSBY_REDIRECT_URL}/entity/${id}`}></Link>,
                            }}
                          >
                            business.you_are_associated
                          </Trans>
                        </Alert>
                      </Box>
                    )}
                    <Content>
                      <Box marginBottom="2.5rem" maxWidth={36}>
                        <DetailList id="entity-info">
                          <ListItem label={t('common.status')}>
                            <InlineTag color={entity.dissolution.isDissolved ? 'danger' : 'success'}>
                              {entity.dissolution.isDissolved ? (
                                <Trans>common.dissolved</Trans>
                              ) : (
                                <Trans>common.active</Trans>
                              )}
                            </InlineTag>
                          </ListItem>
                          {entity.dissolution.isDissolved && !!entity.dissolution.date && (
                            <ListItem label={t('business.dissolution.dissolved_on')}>
                              {format(new Date(entity.dissolution.date), 'MM/dd/yyyy')}
                            </ListItem>
                          )}
                          <ListItem label={t('common.permit_number')}>{entity.permitNumber}</ListItem>
                          <ListItem label={t('common.residency_type')}>
                            {publicProfile?.residencyType && entity.residencyType ? (
                              <Trans>type_of_registration.{entity.residencyType}</Trans>
                            ) : (
                              <Trans>residency.no_active_residency_type</Trans>
                            )
                            }
                          </ListItem>
                          {entity.residencyEndDate && entity.status === 'INACTIVE' && (
                            <ListItem label="Ended on">
                              {format(new Date(entity.residencyEndDate), 'MM/dd/yyyy')}
                            </ListItem>
                          )}
                          {publicProfile?.registrationDate && entity.registrationDate && (
                            <ListItem label={t('common.registered')}>
                              {format(new Date(entity.registrationDate), 'MM/dd/yyyy')}
                            </ListItem>
                          )}
                        </DetailList>
                        <ProfileDivider />
                        <DetailList>
                          <ListItem label={t('common.entity_type')}>
                          {isForeignEntity && !!entity?.foreignEntityType ? (
                            `${t('business.entityType.FOREIGN_ENTITY')} (${entity.foreignEntityType})`
                          ) : (
                            <Trans>business.entityType.{entity.type}</Trans>
                          )}
                          </ListItem>
                        </DetailList>
                        {displayAddress && (
                          <>
                            <ProfileDivider />
                            <DetailList>
                              {publicProfile?.email && entity?.email && (
                                <ListItem label={t('common.email')}>
                                  <Link href={`mailto:${entity.email}`} rightIcon="open_in_new" id="entity-email" plain>
                                    {entity.email}
                                  </Link>
                                </ListItem>
                              )}
                              {publicProfile?.principalOffice && entity?.principalOffice && (
                                <ListItem label={t('business.principal_office_address')}>
                                  {getAddress(entity.principalOffice)}
                                </ListItem>
                              )}
                            </DetailList>
                          </>
                        )}
                        {displayRegisteredAgent && (
                          <>
                            <ProfileDivider />
                            <DetailList>
                              {publicProfile?.registeredAgentName && entity?.registeredAgent?.name && (
                                <ListItem label={t('business.registered_agent_name')}>
                                  {entity.registeredAgent.name}
                                </ListItem>
                              )}
                              {publicProfile?.registeredAgentRepresentativeName &&
                                entity?.registeredAgent?.representativeName && (
                                  <ListItem label={t('common.attendee')}>
                                    {entity?.registeredAgent?.representativeName}
                                  </ListItem>
                                )}
                              {publicProfile?.registeredAgentMailingAddress &&
                                entity?.registeredAgent?.mailingAddress && (
                                  <ListItem label={t('common.mailing_address')}>
                                    {getAddress(entity?.registeredAgent?.mailingAddress)}
                                  </ListItem>
                                )}
                              {publicProfile?.registeredAgentAddress && entity?.registeredAgent?.address && (
                                <ListItem label={t('common.registered_office_address')}>
                                  {getAddress(entity?.registeredAgent?.address)}
                                </ListItem>
                              )}
                            </DetailList>
                          </>
                        )}
                        {displayIndustry && (
                          <>
                            <ProfileDivider />
                            <DetailList id="entity-industry-info">
                              {publicProfile?.workInZede && entity?.workInZede !== null && (
                                <ListItem label={t('common.regulated_industry')}>
                                  {entity?.workInZede ? <Trans>common.yes</Trans> : <Trans>common.no</Trans>}
                                </ListItem>
                              )}
                              {publicProfile?.industry && entity?.industry && (
                                <ListItem label={t('common.industry_name')}>
                                  <Trans>insurance.application.industry.{entity.industry}</Trans>
                                </ListItem>
                              )}
                              {publicProfile?.regulatoryElection && (
                                <ListItem label={t('common.regulatory_election')}>
                                  {entity?.regulatoryElection ? (
                                    <Trans>regulatory_election.{entity.regulatoryElection}</Trans>
                                  ) : (
                                    <Trans>common.no_regulatory_election</Trans>
                                  )}
                                </ListItem>
                              )}
                              {publicProfile?.regulatoryElectionSub && entity?.regulatoryElectionSub && (
                                <ListItem label={t('common.subnational_jurisdiction')}>
                                  {entity.regulatoryElectionSub}
                                </ListItem>
                              )}
                              {publicProfile?.natureOfBusiness && entity?.natureOfBusiness && (
                                <ListItem label={t('common.nature_of_business')}>{entity.natureOfBusiness}</ListItem>
                              )}
                            </DetailList>
                          </>
                        )}
                        {displayInsurance && (
                          <>
                            <ProfileDivider />
                            <DetailList id="entity-insurance-info">
                              {publicProfile?.insurance && entity?.insurance?.insurance !== null && (
                                <ListItem label={t('common.insurance')}>
                                  {entity?.insurance?.insurance ? (
                                    <Trans>common.covered</Trans>
                                  ) : (
                                    <Trans>common.not_covered</Trans>
                                  )}
                                </ListItem>
                              )}
                              {publicProfile?.riskScore && entity?.insurance?.scoreScale !== null && (
                                <ListItem label={t('common.risk_score')}>{entity?.insurance?.scoreScale}</ListItem>
                              )}
                            </DetailList>
                          </>
                        )}
                        {displayLegalReps && (
                          <>
                            <ProfileDivider />
                            <DetailList id="entity-representatives-info">
                              <ListItem label={t('common.legal_reps')}>
                                <Box display="flex" flexDirection="column" gap="0.25">
                                  {[...organizers]
                                    .sort((a, b) => a.lastName.localeCompare(b.lastName))
                                    .map((o) => (
                                      <Box display="flex" flexDirection="column">
                                        <Text variant="h6">{`${o.firstName} ${o.lastName}`}</Text>
                                        {publicProfile.mainOrganizerEmail && o.email && (
                                          <Text variant="p-sm">{o.email}</Text>
                                        )}
                                      </Box>
                                    ))}
                                </Box>
                              </ListItem>
                            </DetailList>
                          </>
                        )}
                      </Box>
                      <Aside>
                        <Paper style={{ padding: '2.5rem' }} borderRadius={6} id="side-content">
                          <Box justifyContent="center" display="flex" width="100%" marginBottom="32px">
                            <Box flexShrink={0}>
                              <StaticImage
                                src="./../../images/prosperazede.svg"
                                alt="Prospera Zede logo"
                                width={64}
                                height={64}
                              />
                            </Box>
                            <Separator />
                            <Box textAlign="center" paddingTop={0.25}>
                              <VerifiedIcon />
                              <Text variant="p-sm" color="success">
                                <Trans>common.verified</Trans>
                              </Text>
                            </Box>
                          </Box>
                          <Box>
                            <Text variant="p" color="gray">
                              <Trans components={{ entityName: entity.fullName }}>business.is_registered_to</Trans>
                            </Text>
                          </Box>
                        </Paper>
                      </Aside>
                    </Content>
                  </Info>
                </Box>
              </EntityDetailsWrapper>
            </ContentWrapper>
          </MainMenu>
        </>
      ) : (
        <Box height="calc(100vh - 84px)" justifyContent="center" alignItems="center" display="flex" width="100%">
          <Loader />
        </Box>
      )}
    </>
  );
};

export default PublicEntityDetailPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
